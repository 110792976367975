<template>
  <div class="pie-block w-full">
    <div
      :class="`chart-block pie-charts w-full flex flex-column align-item-center ${
        isComplex ? 'complex' : ''
      }`"
    >
      <div v-if="label" class="chart-label pie-charts__chart-label">
        {{ label }}
      </div>
      <DoughnutChart
        :chartData="chartFinalData"
        :width="300"
        :options="charOptions"
        :plugins="plugins"
      ></DoughnutChart>
    </div>
  </div>
</template>

<script>
import { DoughnutChart } from "vue-chart-3";
import { ref, toRefs } from "@vue/reactivity";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useStore } from "vuex";
import useHtmlLegend from "./../mixins/useHtmlLegend";
import useEmptyDoughnutPlugin from "../mixins/useEmptyDoughnutPlugin";
import "chartjs-plugin-datalabels";
import {getColorFromContext} from "@/feature/analytics/mixins/colorUtils";
export default {
  name: "doughnut-chart",
  components: { DoughnutChart },
  props: {
    label: {
      type: String,
      required: false,
    },
    chartLabels: {
      type: Array,
    },
    chartData: {
      type: Array,
    },
    legend: {
      type: Boolean,
      default: false,
    },
    legendPosition: {
      type: String,
      default: "top",
    },
    htmlLegend: {
      type: String,
      default: "",
    },
    isComplex: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const doughnutProps = toRefs(props),
      store = useStore(),
      { htmlLegendPlugin } = useHtmlLegend(),
      { emptyDoughnutPlugin } = useEmptyDoughnutPlugin();
    const chartFinalData = ref(null),
      charOptions = ref({
        plugins: {
          datalabels: {
            formatter: (val, context) => val ? `${Math.floor(val)}` : '',
            color: (context) => getColorFromContext(context),
            borderColor: (context) => getColorFromContext(context),
            font: {
              size: 16,
              weight: "600"
            },
            padding: 6
          },
          emptyDoughnut: {
            color: "rgb(236, 238, 239)",
            width: 3,
            radiusDecrease: 20,
          },
          legend: {
            maxWidth: 500,
            position: "right",
            display: true,
            labels: {
              padding: 20,
              color: "#545454",
              font: {
                size: 14,
              },
            },
          },
        },
      }),
      plugins = ref([emptyDoughnutPlugin]),
      processingData = ref([]),
      chartHeight = ref(400);

    const winWidth = computed(() => store.state.width);

    onBeforeMount(() => {
      charOptions.value.plugins.legend.position =
        doughnutProps.legendPosition.value;
      charOptions.value.plugins.legend.display = doughnutProps.legend.value;
      if (doughnutProps.htmlLegend.value) {
        charOptions.value.plugins.htmlLegend = {};
        charOptions.value.plugins.htmlLegend.containerID =
          doughnutProps.htmlLegend.value;
        plugins.value.push(htmlLegendPlugin);
      }
      if (window.innerWidth < 1038) {
        charOptions.value.plugins.legend.position = "top";
        charOptions.value.plugins.legend.labels.font.size = 12;
        charOptions.value.plugins.legend.maxWidth = 300;
        // chartHeight.value = 600;
      }

      chartFinalData.value = {
        labels: doughnutProps.chartLabels.value,
        datasets: [
          {
            data: doughnutProps.chartData.value,
            backgroundColor: ["#C0FF72", "#CB6BE6", "#c12cb3"],
          },
        ],
      };
    });

    return {
      chartFinalData,
      charOptions,
      processingData,
      winWidth,
      chartHeight,
      plugins,
    };
  },
};
</script>

<style lang="scss" scoped>
.pie-block {
  .pie-charts {
    margin-top: 20px;
    min-height: 250px;
    margin-bottom: 25px;

    width: 30%;

    &.complex {
      width: 50%;
    }

    &__chart-label {
      margin-bottom: 10px;
    }
  }
}
</style>
